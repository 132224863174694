<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>绑定银行卡</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="main__container">
      <a-form class="advanced-search-form" :form="form">
        <a-row>
          <a-col :span="9">
            <a-form-item label="持卡人">
              <a-input
                v-decorator="[
                  'personName',
                  {
                    rules: [{ required: true, message: '请输入持卡人姓名' }]
                  }
                ]"
                placeholder="请输入持卡人姓名"
              />
            </a-form-item>
          </a-col>
          <a-col :span="9">
            <a-form-item label="身份证号">
              <a-input
                v-decorator="[
                  'personNo',
                  {
                    rules: [
                      { required: true, message: '请输入持卡人身份证号' },
                      { validator: idCardCheck, message: '身份证号格式错误' }
                    ]
                  }
                ]"
                placeholder="请输入持卡人身份证号"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="9">
            <a-form-item label="证件有效期开始时间">
              <a-date-picker
                v-decorator="[
                  'timeScore',
                  {
                    rules: [
                      { required: true, message: '请选择证件有效期开始时间' }
                    ]
                  }
                ]"
                class="start__date__picker"
              />
            </a-form-item>
          </a-col>
          <a-col :span="9">
            <a-form-item label="证件有效期结束时间">
              <a-date-picker
                class="end__date__picker"
                v-decorator="['timeScores']"
              />
              <a-tooltip>
                <template slot="title">
                  若长期有效，结束时间可不选择
                </template>
                <a-icon type="info-circle" class="icon--tip" />
              </a-tooltip>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="9">
            <a-form-item label="卡号">
              <a-input
                placeholder="请输入绑定银行卡号"
                v-decorator="[
                  'cardNo',
                  {
                    rules: [{ required: true, message: '请输入绑定银行卡号' }]
                  }
                ]"
                @blur="checkCardNoDetail"
              />
            </a-form-item>
          </a-col>
          <a-col :span="9">
            <a-form-item label="银行卡类型">
              <a-select
                show-search
                :value="bankName"
                placeholder="请输入"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                @search="handleChange"
                @change="value => (bankName = value)"
                style="width: 100%"
              >
                <a-select-option
                  v-for="item in searchBankList"
                  :key="item.bankNo"
                  :value="item.bankNo"
                >
                  {{ item.bankName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="9">
            <a-form-item label="开户银行所在地">
              <a-tree-select
                v-decorator="[
                  'bankArea',
                  {
                    rules: [{ required: true, message: '请选择' }]
                  }
                ]"
                style="width: 100%"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="areaData"
                :replace-fields="areaTreeFields"
                tree-node-filter-prop="title"
                :allow-clear="true"
                @change="onConfirmArea"
              ></a-tree-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="9">
            <a-form-item label="银行绑定手机号" class="form__item">
              <div class="form__item--center">
                <a-input
                  placeholder="请输入手机号"
                  v-decorator="[
                    'bankPhone',
                    {
                      rules: [
                        { required: true, message: '请输入银行绑定手机号' }
                      ]
                    }
                  ]"
                  class="input__mobile"
                />
                <a-button
                  type="primary"
                  class="btn--send"
                  @click="onSendSms"
                  v-if="!sendStatus"
                >
                  发送验证码
                </a-button>
                <a-button
                  v-if="sendStatus"
                  :type="!disableClick ? 'primary' : 'default'"
                  :disabled="disableClick"
                  class="btn--send"
                  @click="onSendSms"
                >
                  {{ captchaSendMsg }}
                </a-button>
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="9">
            <a-form-item label="验证码">
              <a-input placeholder="请输入" v-decorator="['authCode']" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-space class="operation">
          <a-button type="primary" @click="onSubmit">提交</a-button>
          <a-button @click="onCancel">取消</a-button>
        </a-space>
      </a-form>
    </div>
  </a-layout>
</template>

<script>
import BreadcrumbItem from "@/components/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb";
import {
  onApplyCard,
  onFetchCardNo,
  onReportCard,
  searchBank
} from "@/services/Archives";
import { loadArea } from "@/services/DealerService";
import { dtFormat } from "@/components/DateUtils";

const areaTreeFields = {
  children: "subAreas",
  title: "areaName",
  key: "areaCode",
  value: "areaCode"
};
export default {
  name: "BindBankCard",
  components: { Breadcrumb, BreadcrumbItem },
  data() {
    return {
      bankCode: "",
      bankName: "",
      areaTreeFields,
      areaData: [],
      eqSeqId: "",
      transId: "",
      orderId: "",
      cardType: "",
      hostProvinceCode: "",
      hostCityCode: "",
      hostCountyCode: "",
      searchBankList: [],
      form: this.$form.createForm(this),
      sendStatus: false,
      captchaSendMsg: "重新获取",
      disableClick: false
    };
  },
  mounted() {
    this.fetchAreaList();
  },
  methods: {
    // 发送验证码
    onSendSms() {
      if (this.form.getFieldsValue().bankPhone === "") {
        this.$message.warning("请先填写电话号码");
        return;
      } else if (this.form.getFieldsValue().bankPhone.length !== 11) {
        this.$message.warning("电话号码不足11位");
        return;
      } else {
        this.sendStatus = true;
        this.disableClick = true;
        let params = {
          currentOperationUserId: localStorage.getItem("userId"),
          corporationId: localStorage.getItem("corporationId"),
          outCustId: null,
          bankName: this.bankName,
          bankCardNo: this.form.getFieldsValue().cardNo,
          userName: this.form.getFieldsValue().personName,
          certType: "00",
          idCardNo: this.form.getFieldsValue().personNo,
          certValidityType: this.form.getFieldsValue().timeScores ? "0" : "1",
          certBeginDate: dtFormat(this.form.getFieldsValue().timeScore)
            .split("-")
            .join(""),
          certEndDate: this.form.getFieldsValue().timeScores
            ? dtFormat(this.timeScores)
                .split("-")
                .join("")
            : "",
          cardMp: this.form.getFieldsValue().bankPhone,
          trxDeviceInfForm: {
            trxMobilNum: this.form.getFieldsValue().bankPhone,
            trxDeviceType: "1",
            trxDeviceIp: "115.197.190.76",
            trxDeviceMac: "000",
            trxDeviceImei: "000",
            trxDeviceImsi: "000",
            trxDeviceIccId: "000",
            trxDeviceWfifiMac: "00",
            trxDeviceGps: "000"
          }
        };
        onApplyCard(params).then(resp => {
          if (resp.data.code === "SUCCESS") {
            this.orderId = resp.data.data.orderId;
            this.reqSeqId = resp.data.data.reqSeqId;
            this.transId = resp.data.data.transId;
            this.countDown();
          } else {
            this.disableClick = false;
          }
        });
      }
    },
    // 获取地理信息
    fetchAreaList() {
      loadArea().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.areaData = resp.data.data;
          this.areaData.forEach(item => {
            item.subAreas.forEach(items => {
              items.subAreas = [];
            });
          });
        }
      });
    },
    // 确认区域
    onConfirmArea(values) {
      if (values && values.length === 2) {
        this.hostProvinceCode = values + "0000";
        this.hostCityCode = "";
      } else {
        this.hostProvinceCode = "";
        this.hostCityCode = values + "00";
      }
    },
    // 倒计时
    countDown() {
      this.disableClick = true;
      const num = 60;
      this.captchaSendMsg = `重新获取(${num})`;
      for (let i = num; i >= 0; i--) {
        setTimeout(() => {
          this.captchaSendMsg = `重新获取(${i})`;
          if (i === 0) {
            this.disableClick = false;
            this.captchaSendMsg = "重新获取";
          }
        }, (num - i + 1) * 1000);
      }
    },
    // 身份证号格式检查
    idCardCheck(rule, value) {
      if (value && value.length === 18) {
        this.idCardIsCorrect = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
          value
        );
        // 满足18位校验，然后需要校验最后一位校验码
        if (this.idCardIsCorrect) {
          // 得到的最后一位校验码
          const checkDigit = this.checkDigitCheck(value);
          // 取到当前身份证号的最后一位
          const number = value.substring(17, 18);
          if (checkDigit || checkDigit === 0) {
            if (checkDigit === "X") {
              this.idCardIsCorrect =
                String(number) === "X" || String(number) === "x";
            } else {
              this.idCardIsCorrect = parseInt(number) === parseInt(checkDigit);
            }
          } else {
            this.idCardIsCorrect = false;
          }
        }
      } else {
        this.idCardIsCorrect = false;
      }
      return this.idCardIsCorrect;
    },
    // 检查身份证号的最后一位校验码
    checkDigitCheck: function(idCard) {
      const sum =
        parseInt(idCard.substring(0, 1)) * 7 +
        parseInt(idCard.substring(1, 2)) * 9 +
        parseInt(idCard.substring(2, 3)) * 10 +
        parseInt(idCard.substring(3, 4)) * 5 +
        parseInt(idCard.substring(4, 5)) * 8 +
        parseInt(idCard.substring(5, 6)) * 4 +
        parseInt(idCard.substring(6, 7)) * 2 +
        parseInt(idCard.substring(7, 8)) +
        parseInt(idCard.substring(8, 9)) * 6 +
        parseInt(idCard.substring(9, 10)) * 3 +
        parseInt(idCard.substring(10, 11)) * 7 +
        parseInt(idCard.substring(11, 12)) * 9 +
        parseInt(idCard.substring(12, 13)) * 10 +
        parseInt(idCard.substring(13, 14)) * 5 +
        parseInt(idCard.substring(14, 15)) * 8 +
        parseInt(idCard.substring(15, 16)) * 4 +
        parseInt(idCard.substring(16, 17)) * 2;
      const remainder = sum % 11;
      switch (remainder) {
        case 0:
          return 1;
        case 1:
          return 0;
        case 2:
          return "X";
        case 3:
          return 9;
        case 4:
          return 8;
        case 5:
          return 7;
        case 6:
          return 6;
        case 7:
          return 5;
        case 8:
          return 4;
        case 9:
          return 3;
        case 10:
          return 2;
        default:
          return null;
      }
    },
    // 取消
    onCancel() {
      this.$router.go(-1);
    },
    handleChange(value) {
      this.searchBankList = [];
      const params = {
        keyword: value,
        pageNum: 1,
        pageSize: 2000
      };
      searchBank(params).then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.searchBankList = data.records;
        }
      });
    },
    // 输入银行卡号 失焦后触发
    checkCardNoDetail() {
      onFetchCardNo(this.form.getFieldsValue().cardNo).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data.bankName;
          this.bankCode = data.match(/\((.*?)\)/);
          this.bankName = data.split("\n")[0];
          if (resp.data.data.cardType === "借记卡") {
            this.cardType = "DC";
          } else {
            this.cardType = "CC";
          }
        }
      });
    },
    // 提交
    onSubmit() {
      this.form.validateFields((error, value) => {
        if (!error) {
          if (this.form.getFieldsValue().authCode === "") {
            this.$message.warning("请填写验证码");
          } else {
            let params = {
              currentOperationUserId: localStorage.getItem("userId"),
              corporationId: localStorage.getItem("corporationId"),
              bankCardNo: value.cardNo,
              cardType: this.cardType,
              reqSeqId: this.reqSeqId,
              cityCode: this.hostCityCode,
              provinceCode: this.hostProvinceCode,
              cardMp: value.bankPhone,
              verifyCode: value.authCode
            };
            onReportCard(params).then(resp => {
              if (resp.data.code === "SUCCESS") {
                this.$router.replace({
                  name: "BindBankCardSuccess",
                  params: {
                    bankName: this.bankName,
                    bankCode: value.cardNo.slice(-4)
                  },
                  query: { price: this.$route.query.price }
                });
              } else {
                this.$message.error(resp.data.errorMsg);
              }
            });
          }
        }
      });
      // this.$warning({
      //   title: "提交信息不匹配",
      //   content:
      //     "你提交的持卡人身份信息（姓名、身份证号、绑定手机号）与银行系统预留的信息不一致，请核对后重新提交。",
      //   okText: "返回修改",
      //   width: "45%"
      // });
      // this.$warning({
      //   title: "银行卡已绑定",
      //   content: "当前添加的银行卡已绑定，可在充值/提现方式选择列表中查看",
      //   okText: "返回修改",
      //   width: "45%"
      // });
    }
  }
};
</script>

<style scoped>
.main__container {
  min-height: 88vh;
  margin: 24px;
  background-color: white;
  box-sizing: border-box;
  padding: 20px;
}

/deep/ .ant-form-item .ant-form-item-label {
  min-width: 141px !important;
  text-align: left;
}

/deep/ .ant-form-item {
  margin-right: 40px;
}

.start__date__picker {
  width: 100%;
}

.end__date__picker {
  width: calc(100% - 26px);
}

.icon--tip {
  font-size: 16px;
  color: rgba(153, 153, 153, 1);
  margin-left: 10px;
}

.input__mobile {
  width: calc(100% - 95px);
}

.btn--send {
  width: 85px;
  height: 31px;
  font-size: 12px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form__item {
  display: flex;
  align-items: center;
}

.form__item--center {
  display: flex;
  align-items: center;
}

.operation {
  margin-left: 141px;
  margin-top: 50px;
}
</style>
